import React, { useEffect, useState } from 'react'
import Cards from './Cards'
import NewCards from './NewCards'

const ProductsSection = ({data}) => {
  const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      setIsVisible(true);
    }, []);
  return (
    <div className='container flex items-center justify-center'>
    <div className={` mt-10  gap-10 grid lg:grid-cols-2  transition-all duration-1000 ${
                isVisible
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-10"
              }`}>
      
      {data.map((product) => (
        <NewCards key={product.id} product={product} />
      ))}
     
    </div>
    </div>
  )
}

export default ProductsSection