import React, { useState, useEffect } from 'react';

import { X, Clock, Bell, Gift, ArrowRight, Sparkles } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';


const useCountdown = (initialTime) => {
  const calculateTimeLeft = (savedTimestamp) => {
    const now = new Date().getTime();
    const targetTime = savedTimestamp + initialTime * 1000; // initialTime in seconds

    const difference = targetTime - now;
    
    if (difference <= 0) return { hours: 0, minutes: 0, seconds: 0 };

    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    
    return { hours, minutes, seconds };
  };

  const getSavedTimestamp = () => {
    const savedTimestamp = localStorage.getItem("countdownStart");
    return savedTimestamp ? parseInt(savedTimestamp, 10) : null;
  };

  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTimestamp = getSavedTimestamp();
    if (savedTimestamp) return calculateTimeLeft(savedTimestamp);

    const now = new Date().getTime();
    localStorage.setItem("countdownStart", now);
    return calculateTimeLeft(now);
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const savedTimestamp = getSavedTimestamp();
      setTimeLeft(calculateTimeLeft(savedTimestamp));
    }, 1000);

    return () => clearInterval(timer);
  }, [initialTime]);
  
  return timeLeft;
};


const GradientBanner = () => {
  
  
  return (
    <div className="bg-gradient-to-r from-gray-800 via-gray-700 to-gray-900 text-gray-100 relative overflow-hidden">
      <div className="absolute inset-0 bg-[url('data:image/svg+xml,...')] opacity-10"></div>
      <div className="max-w-7xl mx-auto py-4 px-4 relative">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
          <div className="flex items-center gap-3">
            <div className="bg-gray-700 p-2 rounded-lg">
              <Sparkles className="h-5 w-5 text-purple-400" />
            </div>
            <div>
              <p className="font-medium">Looking for a Resume That Stands Out?</p>
              <p className="text-sm text-purple-400">From boring to WOW, we’ll craft a resume that gets recruiters saying, "Let’s call them!"
              </p>
            </div>
          </div>
          
          <div className="flex items-center gap-4">
            <Link to="/resume-maker" >
            <div className="bg-purple-500 text-gray-900 px-4 py-1 rounded-full text-sm font-medium hover:bg-purple-400 transition-colors flex items-center gap-2">
              Get Your Resume Now!
              <ArrowRight className="h-4 w-4" />
            </div>
            </Link>
           
            {/* <button onClick={onClose} className="text-gray-400 hover:text-gray-200">
              <X className="h-5 w-5" />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};


const ResumeServiceBanner = ({ onClose }) => {
  const timeLeft = useCountdown(5 * 60 * 60 + 59 * 60 + 24);
  const [isHovered, setIsHovered] = useState(false);
  
  return (
    <div className="relative bg-gradient-to-r from-purple-900 via-gray-900 to-blue-900">
      <div className="absolute inset-0 bg-gradient-to-r from-purple-600/10 to-blue-600/10"></div>
      <div className="max-w-7xl mx-auto py-3 px-4 relative">
        <div className="flex items-center justify-between flex-col md:flex-row gap-4">
          <div className="flex items-center gap-4">
            <div className="bg-white/10 p-2 rounded-full">
              <Gift className="h-5 w-5 text-purple-400" />
            </div>
            <div>
              <p className="font-medium text-white">
              Hurry Up! This Deal Won’t Wait! 
              </p>
              <p className="text-sm text-gray-300">Get ₹50 OFF with code <span className='text-yellow-300 font-[500]'>OFF50</span> – because saving money feels as good as landing an internship!</p>
            </div>
          </div>
          
          <div className="flex items-center gap-6">
            <div className="flex gap-2">
              {Object.entries(timeLeft).map(([unit, value]) => (
                <div key={unit} className="bg-white/10 px-3 py-1 rounded-lg backdrop-blur-sm">
                  
                  <span className="text-white font-mono">{value}</span>
                  <span className="text-xs text-gray-300 ml-1">{unit[0]}</span>
                </div>
              ))}
            </div>
            {/* <button 
              onClick={onClose}
              className="text-white/80 hover:text-white transition-colors"
            >
              <X className="h-5 w-5" />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};







const ResumeMakerBanner = () => {
  const [visibleBanners, setVisibleBanners] = useState({
    minimal: true,
    gradient: true,
    interactive: true,
    floating: true
  });

  const handleClose = (bannerKey) => {
    setVisibleBanners(prev => ({
      ...prev,
      [bannerKey]: false
    }));
  };

  return (
    <div className="space-y-4 z-[110] md:mt-20 mt-[3.8rem] ">
    
        <ResumeServiceBanner  />
    
      {visibleBanners.floating && (
        <GradientBanner onClose={() => handleClose('floating')} />
      )}
      
    </div>
  );
};

export default ResumeMakerBanner;