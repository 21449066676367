import React from 'react'
import SessionCard from './SessionCard'

const MySession = ({sessions}) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
      {sessions?.map((session) => {
        return (
          <SessionCard key={session.session_id} session={session}/>
        )
      })}
    </div>
  )
}

export default MySession