import { createContext, useLayoutEffect, useState } from "react";

export const MainContext = createContext();

export const MainProvider = ({ children }) => {

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  


  // useLayoutEffect(() => {
  //   if(window.localStorage.getItem("user-info")){
  //     console.log(window.localStorage.getItem("user-info"));
  //     setIsLoggedIn(true);
      
  //   }
  // },[])

  return (
    <MainContext.Provider value={{
      isLoggedIn,
      setIsLoggedIn,
      user,setUser,
      error,setError,
      isLoading, setIsLoading
     
    }}>{children}</MainContext.Provider>
  );
}