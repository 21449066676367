export const formatDate = (inputDate, inputTime, isoStringForYear) => {
  console.log("Input Date (IST):", inputDate);
  console.log("Input Time (IST):", inputTime);
  console.log("ISO String for Year:", isoStringForYear);

  // Extract year from the provided ISO string
  const year = new Date(isoStringForYear).getUTCFullYear();
  console.log("Extracted Year:", year);

  // Parse inputDate as "DD MMM"
  const [day, month] = inputDate.split(" ");

  // Map month abbreviation to month number
  const monthMap = {
    Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
    Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11,
  };

  const monthIndex = monthMap[month];
  if (monthIndex === undefined) {
    throw new Error(`Invalid month in inputDate: ${month}`);
  }

  // Create Date object in IST by adding 5 hours and 30 minutes offset
  const date = new Date(Date.UTC(year, monthIndex, Number(day), 5, 30));

  // Extract hours and minutes from the input time
  const [time, meridian] = inputTime.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  // Convert to 24-hour format if necessary
  if (meridian === "PM" && hours < 12) hours += 12;
  if (meridian === "AM" && hours === 12) hours = 0;

  // Add the input time to the IST-adjusted date
  date.setHours(hours, minutes, 0, 0);

  // Convert to ISO format
  const isoString = date.toISOString();

  console.log("Converted Date (UTC):", isoString);

  return isoString;
};
