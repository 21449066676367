"use client"

import React, { useState, useRef, useEffect } from 'react'
import { Info } from 'lucide-react'


export function CustomTooltip({ content }) {
  const [isVisible, setIsVisible] = useState(false)
  const tooltipRef = useRef(null)
  const triggerRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target ) &&
          triggerRef.current && !triggerRef.current.contains(event.target )) {
        setIsVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="relative inline-block">
      
       
        {/* <Info className="h-6 w-6  text-red-500 md:block hidden" ref={triggerRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
         /> */}
        
     
       <button onClick={() => setIsVisible(!isVisible)} className=" ">
      <Info className="h-6 w-6 text-red-500" />
      </button>
      
      {isVisible && (
        <>
        <div
          ref={tooltipRef}
          className="absolute -translate-x-1/2 ml-2  transform md:-translate-y-1/2 text-white text-sm rounded px-2 py-1 md:whitespace-nowrap mt-3 hidden md:block"
        >
          {content}
          {/* <div className="absolute w-full right-full top-1/2 transform md:-translate-y-1/2 border-8 border-transparent border-t-gray-800"></div> */}
        </div>
        <div
          ref={tooltipRef}
          className="absolute -translate-x-1/2 ml-2  transform md:-translate-y-1/2 text-white text-sm rounded px-2 py-1 md:hidden block w-[18rem] md:whitespace-nowrap mt-3 "
        >
          {content}
          {/* <div className="absolute w-full right-full top-1/2 transform md:-translate-y-1/2 border-8 border-transparent border-t-gray-800"></div> */}
        </div>
        </>
        
      )}
    </div>
  )
}

