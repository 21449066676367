import React, { useContext } from 'react';
import { MainContext } from '@/context/MainContext';
import UserAvatar from '../UserAvatar/UserAvatar';
import LoginComponent from '../LoginComponent/LoginComponent';

const LoginButton = () => {
  const { user } = useContext(MainContext);

  return (
    <>
      {user ? (
        <UserAvatar image={user.profile_picture} />
      ) : (
        <LoginComponent>
          <button className="flex items-center justify-center px-6 py-2 text-sm font-medium text-white bg-[#3c0072] border  rounded-full transition-all hover:bg-gray-800 hover:shadow-lg">
              Login
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
        </LoginComponent>
      )}
    </>
  );
};

export default LoginButton;
