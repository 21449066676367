import React, { useEffect, useState } from 'react'
import DateTimePicker from './DateTimePicker';

const Card = ({ children, className }) => (
  <div
    className={`bg-gray-900/50 backdrop-blur-xl border border-gray-800/60 rounded-xl hover:border-violet-500/30 transition-all duration-300 ${className}`}
  >
    {children}
  </div>
);

const stats = [
  { value: "1000+", label: "Students Enrolled" },
  { value: "4.9", label: "Average Rating" },
  { value: "1 Hr", label: "Video Meet" },
];

const CallSectionV2 = ({session}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);
  return (
    <div className="min-h-screen md:-mt-16 bg-gray-950 text-white">
      <div className="fixed inset-0 -z-10 bg-[linear-gradient(to_bottom,rgba(0,0,0,0)_0%,rgba(0,0,0,0.8)_100%)]">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSA2MCAwIEwgMCAwIDAgNjAiIGZpbGw9Im5vbmUiIHN0cm9rZT0icmdiYSg5OSwgMTAyLCAyNDEsIDAuMSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvcGF0dGVybj48L2RlZnM+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmlkKSIvPjwvc3ZnPg==')] opacity-20"></div>
      </div>

      <div className="pt-24 lg:pt-40 pb-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 gap-16 lg:gap-24 items-center">
            <div
              className={`space-y-8 transition-all duration-1000 ${
                isVisible
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-10"
              }`}
            >
              <div className="inline-flex  items-center gap-2 text-sm bg-gray-900/50 px-4 py-2 rounded-full border border-gray-800/50 backdrop-blur-sm ">
                <span className="w-2 h-2 bg-green-400 rounded-full animate-pulse"></span>
                <span className="text-gray-300 font-medium">
                  Limited Time Offer
                </span>
              </div>

              <h1 className="text-4xl sm:text-5xl lg:text-6xl font-black text-white leading-tight">
              One-to-One {" "}
                <span className="relative">
                  <span className="bg-gradient-to-r from-violet-400 to-indigo-400 bg-clip-text text-transparent">
                  Mentorship
                  </span>
                </span>
              </h1>

              <p className="text-gray-400 text-lg sm:text-xl leading-relaxed md:block hidden">
             {session?.description}
              </p>

              

              <div className="grid grid-cols-3 gap-6">
                {stats.map((stat, index) => (
                  <Card key={index} className="p-4 text-center group">
                    <div className="text-2xl font-bold bg-gradient-to-r from-violet-400 to-indigo-400 bg-clip-text text-transparent group-hover:scale-110 transition-transform">
                      {stat.value}
                    </div>
                    <div className="text-sm text-gray-400">{stat.label}</div>
                  </Card>
                ))}
              </div>
            </div>

            <div
              className={`relative group transition-all duration-1000 delay-300 ${
                isVisible
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-10"
              }`}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-violet-600/20 to-indigo-600/20 blur-3xl transition-all duration-500 group-hover:blur-4xl -z-10 "></div>

              <DateTimePicker title="When should we meet?" buttonText={"Book a Session"}  isReschedule={false} dynamicPricing={true} id={101}/>
              </div>
              
              <p className="text-gray-400 text-lg sm:text-xl leading-relaxed md:hidden block">
             {session?.description}
              </p>
            
          </div>
        </div>
      </div>
      </div>
  )
}

export default CallSectionV2