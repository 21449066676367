import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const CourseCard = ({course}) => {
  const navigate = useNavigate();

  function isExpired(expirationDate) {
    const date = new Date(expirationDate);
    return new Date() > date;
  }
  function formatDate(date) {
    // Convert to Date object if the input is a string or other type
    const validDate = new Date(date);

    // Check if the conversion resulted in an invalid Date
    if (isNaN(validDate.getTime())) {
        throw new Error("Invalid date passed to formatDate");
    }

    return validDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
}
  const [expired, setExpired] = useState(isExpired(course.expiry_date));
  return (
    <div className={`w-full max-w-md rounded-lg overflow-hidden shadow-lg transition-colors duration-500 ${
      expired ? 'bg-red-900/20' : 'bg-gray-900'
    }`}>
      <div className="px-6 py-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-white">{course.course_title}</h2>
          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${
            expired ? 'bg-red-500 text-white' : 'bg-green-500 text-black'
          }`}>
            {expired ? 'Expired' : 'Active'}
          </span>
        </div>
        {
          !expired ? (
            <button
      onClick={() => {navigate(`/view-courses/${course.course_id}`)}}
      className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
      aria-label="View Course"
    >
      View Course
    </button>
          ): 
          (
            <button
            onClick={() => {navigate(`/courses/${course.course_id}`)}}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 shadow-md"
            aria-label="View Course"
          >
            Renew Course
          </button>
          )
        }
        <div className="text-sm text-gray-400 mt-4">
          <p>Purchased: {formatDate(course.purchased_date)}</p>
          <p>Expires: {formatDate(course.expiry_date)}</p>
        </div>
      </div>
      <div className="px-6 py-4 bg-gray-800">
        <p className={`text-sm ${
          expired ? 'text-red-400' : 'text-green-400'
        }`}>
          {expired 
            ? 'This item has expired. Please renew if you wish to continue using it.' 
            : 'Learning on your phone is cool, but it’s like watching a Netflix blockbuster on a tiny screen—good, but not great. Switch to a laptop for the full IMAX experience!'}
        </p>
      </div>
    </div>
  )
}

export default CourseCard