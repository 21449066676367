import { cn } from "@/lib/utils";
import Marquee from "../ui/marquee";

const reviews = [
  {
    index: 0,
    name: "Manosh Jyoti Borthakur",
    testimonial:
      "I am incredibly grateful to Charchit for his invaluable support in landing my dream internship at Zomato Headquarters, Gurgaon that too in a coveted domain. From guiding me in crafting a standout CV to mastering the art of cold mailing, his assistance was instrumental in this achievement. Thank you for your unwavering support.",
    imagePath: "Manosh Jyoti Borthakur.jpeg",
    placedAt: "Intern @Zomato",
  },
  {
    index: 1,
    name: "Vanshika Gupta",
    testimonial:
      "Charchit is such an amazing personality! He is worth the time! His cold emailing templates are the best",
    imagePath: "Vanshika Gupta.jpeg",
    placedAt: "Analyst @EY",
  },
  {
    index: 2,
    name: "Khushi Hasija",
    testimonial:
      "EY was my first internship, and let me tell you, chasing it was like trying to find a needle in a haystack. But then I reached out to Charchit Kurra, and he made my cold email game stronger. Next thing I knew, I wasn’t just another email in the inbox—I was getting responses! If you're stuck in the internship grind, trust me, this guy knows how to make those emails pop!",
    imagePath: "Khushi Hasija.jpeg",
    placedAt: "Intern @EY",
  },
  {
    index: 3,
    name: "Chinmay Das",
    testimonial:
      "One of the best sessions I have attended with full of practical insights.",
    imagePath: "Chinmay Das.jpeg",
    placedAt: "Intern @Niti Aayog",
  },
  {
    index: 4,
    name: "Shruti Arya",
    testimonial:
      "Really glad to have been guided by Charchit, landed my first internship and that too at zomato all because of his tricks and ways.Now that we’ve joined the same office together it’s really good to know the non mentoring and fun side of him too!",
    imagePath: "Shruti Arya.jpeg",
    placedAt: "Intern @Zomato",
  },
  {
    index: 5,
    name: "Yash Negi",
    testimonial:
      "Thanks to Charchit's invaluable guidance and resources because of it I secured an internship at Deloitte as a research intern. The insights and support I received truly helped me excel in my journey.",
    imagePath: "Yash Negi.jpeg",
    placedAt: "Intern @Deloitte",
  },
  {
    index: 6,
    name: "Riya Gupta",
    testimonial:
      "Hey so I think I connected with charchit very very randomly - When I apparently already did a lot of cold mailing to Blinkit and then somebody revert back , it was then that I got in touch w him to have an idea about the interview - But it’s that day to today that he has always been a supporter and a person to always go to have a push for yourself",
    imagePath: "Riya Gupta.jpeg",
    placedAt: "Intern @Blinkit",
  },
  {
    index: 7,
    name: "Abiha Zaidi",
    testimonial:
      "Charchit is an exceptional mentor who transformed my career. Coming from an online college and facing constant rejections, I was unsure of my chances. His course was meticulously designed and full of actionable strategies. With his guidance, I started getting interview calls from top companies like EY and Deloitte. Even when I struggled during Deloitte's process, Charchit stood by me, offering constant support. Thanks to him, I secured a position at a Big 4 firm. If you're looking for a mentor who truly cares, Charchit is the one!",
    imagePath: "Abiha Zaidi.jpeg",
    placedAt: "Intern @Deloitte",
  },
  {
    index: 8,
    name: "Anurag Thakur",
    testimonial:
      "I am really grateful for your guidance and tools you provided which compelled to start finding the summer internship!",
    imagePath: "Anurag Thakur.jpeg",
    placedAt: "Summer Intern @EY",
  },
  {
    index: 9,
    name: "Yana Bedi",
    testimonial:
      "Charchit has really helped me get my professional line on track and guided me by perfectly analysing my profile and providing the best solutions for the same. Please book a 1:1 mentorship with him, and no chance that the outcome will not be what you desire!",
    imagePath: "Yana Bedi.jpeg",
    placedAt: "Intern @Grant Thornton",
  },
  {
    index: 10,
    name: "Arman",
    testimonial:
      "Charchit's cold emailing course was a game-changer for me! His strategies helped me craft impactful emails, which directly led to securing an internship at Frost & Sullivan. I’m truly grateful for his guidance and practical insights. Thank you, Charchit.",
    imagePath: "Arman.jpeg",
    placedAt: "Intern @Frost and Sullivan",
  },
  {
    index: 11,
    name: "Indrakshi",
    testimonial:
      "A well structured, step-by-step and well comprehensive guide which can be easily used to grab the best internship offers via companies that we dream of!",
    imagePath: "Indrakshi.jpeg",
    placedAt: "Intern @Swiggy",
  },
  {
    index: 12,
    name: "Aakanksha",
    testimonial:
      "Charchit Kurra is an amazing friend and an incredibly supportive person. He's always willing to lend a helping hand and offer valuable guidance.",
    imagePath: "Aakanksha.jpeg",
    placedAt: "Intern @Grant Thornton",
  },
  {
    index: 13,
    name: "Somya",
    testimonial:
      "I'm truly grateful for your invaluable guidance on cold emailing. Your insights were gold in my acceptance to GT as an intern!",
    imagePath: "Somya.jpeg",
    placedAt: "Intern @Grant Thornton",
  },
  {
    index: 14,
    name: "Shouvik",
    testimonial:
      "I Didn’t really know what companies to target or how to even get an internship in 1st year but charchit guided me throughout. With his help and guidance i could get an offer letter from almost all the companies that i approached.",
    imagePath: "Shouvik.jpeg",
    placedAt: "Intern @Frost and Sullivan",
  },
  {
    index: 15,
    name: "Samdisha",
    testimonial:
      "Connecting with Charchit was one of my best decisions, he shared cold emailing hacks to sail through in my journey with real-time examples. Thank you for being so helpful Charchit!",
    imagePath: "Samdisha.jpeg",
    placedAt: "Intern @Ferns n Petals",
  },
  {
    index: 16,
    name: "Riddhi Rajeev",
    testimonial:
      "I never imagined that a single session on cold emailing could help me land my dream Internship. The tips, tricks, and resume/cold emailing templates provided by Charchit were incredible. I don’t think I could have secured a position at Blinkit without Charchit’s guidance. With his support, I’m confident anyone can crack any role in any company!",
    imagePath: "Riddhi Rajeev.jpeg",
    placedAt: "Intern @Blinkit",
  },
  {
    index: 17,
    name: "Prerna Sharma",
    testimonial:
      "Charchit is a wonderful person, always helps people! In my journey as an intern at Grant Thornton, he guided me a lot.",
    imagePath: "Prerna Sharma.jpeg",
    placedAt: "Intern @Grant Thornton",
  },
  {
    index: 18,
    name: "Ushanshi Sharma",
    testimonial:
      "Cold emailing was never on my radar until it was suggested to me by Charchit, and it turned out to be the best career advice I’ve ever received! Thanks to this guidance, I secured a position at my dream company, Blinkit Zomato. Your insights and encouragement have truly made a lasting impact on my career journey. Thanks alot!",
    imagePath: "Ushanshi Sharma.jpeg",
    placedAt: "Data Analyst @Blinkit",
  },
  {
    index: 19,
    name: "Alfiya",
    testimonial:
      "Coming from a Tier-3 college in a Tier-3 city, I was able to land an internship at Zepto. The cold mailing tips are pure magic! Highly recommended for anyone looking to stand out and grab amazing opportunities.",
    imagePath: "Alfiya.jpeg",
    placedAt: "Intern @Zepto",
  },
  {
    index: 20,
    name: "Diya Tyagi",
    testimonial:
      "Charchit is an amazing mentor, and has always been a huge help in all my career-related queries. In my experience, he gives some of the best insight one needs to achieve their early-career goals!",
    imagePath: "Diya Tyagi.jpeg",
    placedAt: "Intern @Zomato",
  },
  {
    index: 21,
    name: "Muskan Bhalla",
    testimonial:
      "Charchit has taught me how to do Internship/Job hunt, he goes that extra length to help you out and is available even after sessions. I got my Invest India internship within days of learning the correct approach from him and I have applied that in finding subsequent opportunities as well.",
    imagePath: "Muskan Bhalla.jpeg",
    placedAt: "Intern @Invest India",
  },
  {
    index: 22,
    name: "Chetna Jaiswal",
    testimonial:
      "From being a fresher to landing a job in EY GDS, Charchit has been of great support throughout my whole journey of getting a job. He is not doubtedly doing a wonderful job in transforming lives.",
    imagePath: "Chetna Jaisawal.jpeg",
    placedAt: "HR Associate @EY",
  },
  {
    index: 23,
    name: "Aashi",
    testimonial:
      "Charchit has been an incredible mentor, always guiding me through challenges and motivating me when I doubted my capabilities. With his timely support, I successfully cracked internships at Grant Thornton and Cars24—forever grateful!",
    imagePath: "Aashi.jpeg",
    placedAt: "Intern @Grant Thornton",
  },
  {
    index: 24,
    name: "Chahat Chaurasia",
    testimonial:
      "I am truly grateful for Charchit's constant guidance and support, which played a significant role in helping me secure an internship at Zomato.",
    imagePath: "Chahat Chaurasia.jpeg",
    placedAt: "Intern @Zomato",
  },
  {
    index: 25,
    name: "Samdisha",
    testimonial:
      "Connecting with Charchit was one of my best decisions, he shared cold emailing hacks to sail through in my journey with real-time examples. Thank you for being so helpful Charchit!",
    imagePath: "Samdisha.jpeg",
    placedAt: "Intern @Ferns n Petals",
  },
  {
    index: 26,
    name: "Humera Khan",
    testimonial:
      "Charchit turned my internship hunt from a nightmare into a breeze. Seriously, the guy's a wizard at this stuff!",
    imagePath: "Humera Khan.jpeg",
    placedAt: "Intern @Boat",
  },
  {
    index: 27,
    name: "Yash",
    testimonial:
      "I never really trusted LinkedIn influencers or their so-called 'hacks,' to land dream internships but in March 2024, I decided to give it a shot. and reached out to charchit, Thanks to his email tricks, templates, and guidance, I landed an internship at Tata 1mg within a week. Later, using a similar approach, I secured opportunities at boAt and Blinkit. His strategies are simple, practical, and effective.",
    imagePath: "Yash.jpeg",
    placedAt: "Intern @Boat",
  },
];

const firstRow = reviews.slice(0, reviews.length);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({ imagePath, name, placedAt, testimonial }) => {
  return (
    <figure
      className={cn(
        "relative md:w-64 w-[15rem] cursor-pointer overflow-hidden rounded-xl border p-4",
        // light styles

        // dark styles
        "border-gray-50/[.1] bg-gray-50/[.10] hover:bg-gray-50/[.15]"
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <img
          className="rounded-full max-w-10 min-w-10 max-h-10"
          alt=""
          src={`/Testimonials/${imagePath}`}
        />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium text-white">
            {name}
          </figcaption>
          <p className="text-xs font-medium text-yellow-400">{placedAt}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm text-white">{testimonial}</blockquote>
    </figure>
  );
};

export function MarqueeDemo() {
  return (
    <div className="relative flex w-full h-full  my-20  flex-col items-center justify-center overflow-hidden rounded-lg  bg-background md:shadow-xl ">
      <Marquee pauseOnHover className="[--duration:100s]">
        {firstRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
      {/* <Marquee reverse pauseOnHover className="[--duration:20s]">
        {secondRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee> */}
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-black hidden md:block"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-black hidden md:block"></div>
    </div>
  );
}
