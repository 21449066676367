import { MainContext } from '@/context/MainContext';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, isAuthenticated }) => {
  const {isLoading} = useContext(MainContext);
  
 
  return  !isLoading ? (isAuthenticated ? children : <Navigate to="/" />) 
  : <div>Loading...</div>;
};

export default PrivateRoute;
