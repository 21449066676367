import React from 'react'
import { Dialog, DialogClose, DialogContent, DialogTrigger } from '../ui/dialog';
import GoogleLoginButton from '../GoogleLoginButton/GoogleLoginButton';

const LoginComponent = ({children}) => {
  return (
    <Dialog>
          <DialogTrigger>
            {children}
          </DialogTrigger>
          <DialogContent className="border-none bg-inherit/0 " closeButton = "right-7 top-7 text-white">
           
            <div className="p-6 bg-secondary-300 rounded-lg space-y-4">
              <div className='text-white  text-center'>We promise, logging in won’t unlock a flood of emails or your ex’s texts. Just your resources anytime you want! </div>
              <GoogleLoginButton />
            </div>
          </DialogContent>
        </Dialog>
  )
}

export default LoginComponent