
import { useState } from 'react'
import { Loader2 } from 'lucide-react'

export default function DarkComingSoonCard() {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1500))
    setMessage('Thank you for subscribing!')
    setEmail('')
    setIsLoading(false)
  }

  return (
    <div className="w-full h-fit  mx-auto bg-gray-900 rounded-lg overflow-hidden shadow-2xl md:flex flex-col  hidden">
      <div className="bg-gradient-to-r from-purple-600 to-pink-600 p-6">
        <h2 className="text-2xl font-bold text-center text-white">Coming Soon</h2>
      </div>
      <div className="p-6 space-y-6">
        <p className="text-center text-gray-300">
          We're working hard to bring you something amazing. Stay tuned!
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
          />
          <button 
            type="submit" 
            className="w-full px-4 py-2 bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 text-white font-semibold rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="flex items-center justify-center">
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Please wait
              </span>
            ) : (
              'Notify Me'
            )}
          </button>
        </form>
        {message && (
          <p className="text-center text-green-400 animate-fade-in-down">
            {message}
          </p>
        )}
      </div>
      
    </div>
  )
}

