import MySession from '@/components/MySession/MySession'
import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { baseURL } from '@/utility/api';
import axios from 'axios';

const MySessionPage = () => {
  const [sessions,setSessions] = useState([])
  useEffect(() => {
    // Fetch data from the backend
    async function fetchData() {
      // Your code here
      const response = await axios.get(`${baseURL}/session/my-sessions`,{ withCredentials: true });
      setSessions(response.data);
    }
    fetchData();
    
  }
  , [])
  return (
    <div className='margin py-4 container space-y-10'>
      <motion.div className="md:container w-full"  initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:0.5,delay:0.5}}>
      
      <h1 className="text-5xl sm:text-6xl font-bold mb-2 text-white text-center">My Sessions</h1>
      </motion.div>
      <motion.div className=""  initial={{opacity:0}}
    whileInView={{opacity:1}}
    transition={{duration:0.5,delay:0.5}}>
      <MySession sessions={sessions}/>
      </motion.div>
      </div>
  )
}

export default MySessionPage