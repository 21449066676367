import CoursePayment from '@/components/PaymentSection/CoursePayment';
import ProductPayment from '@/components/PaymentSection/ProductPayment';
import PaymentSection from '@/components/PaymentSection/ProductPayment'
import { MainContext } from '@/context/MainContext';
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const PaymentPage = () => {
  const location = useLocation();
  const {error} = useContext(MainContext);
  
  return (
    
    <div className='w-full relative'>
      
      {
        location.pathname.includes('course') ? 
        <CoursePayment/> : <ProductPayment/>
      }
    
    </div>
  )
}

export default PaymentPage