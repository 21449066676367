import CourseDesc from '@/components/CourseDesc/CourseDesc'
import CourseDescV2 from '@/components/CourseDesc/CourseDescV2'
import React from 'react'


const CourseDescPage = () => {
  return (
    <div className=''>
      {/* <CourseDesc/> */}
      
       <CourseDescV2/>
 
    </div>
  )
}

export default CourseDescPage