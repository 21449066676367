import React from 'react';
import { useNavigate } from 'react-router-dom';

const SessionConfirmation = ({ inputdate, duration = 60 }) => {
  const date = new Date(inputdate);

  // Format month, day, and weekday in IST
  const month = new Intl.DateTimeFormat('en-US', { month: 'short', timeZone: 'Asia/Kolkata' }).format(date);
  const day = new Intl.DateTimeFormat('en-US', { weekday: 'long', timeZone: 'Asia/Kolkata' }).format(date);
  const weekday = new Intl.DateTimeFormat('en-US', { weekday: 'short', timeZone: 'Asia/Kolkata' }).format(date);

  const daydate = new Intl.DateTimeFormat('en-US', { day: '2-digit', timeZone: 'Asia/Kolkata' }).format(date);

  // Calculate end time in IST
  const endDate = new Date(date.getTime() + duration * 60000);

  // Format start and end times in IST
  const startTime = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'Asia/Kolkata',
  }).format(date);

  const endTime = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'Asia/Kolkata',
  }).format(endDate);

  const navigate = useNavigate();

  return (
    <div className="rounded-lg bg-gray-500 text-white p-4 shadow-sm">
      <div className="flex items-center justify-between">
        <div className="flex items-start gap-4">
          <div className="text-center">
            <div className="text-sm text-gray-200">{month}</div>
            <div className="text-xl font-semibold">{daydate}</div>
           
          </div>
          <div className="space-y-1">
            <h3 className="font-medium">
              {day}
            </h3>
            <p className="text-sm text-gray-200 whitespace-nowrap">
              {startTime} - {endTime} (IST)
            </p>
          </div>
        </div>
        <button
          onClick={() => navigate(-1)}
          className="ml-4 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          Change
        </button>
      </div>
    </div>
  );
};

export default SessionConfirmation;
