import { useContext, useEffect, useState } from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import { Route, Routes } from "react-router-dom";
import HomePage from './pages/HomePage/HomePage';
import ProductsPage from './pages/ProductsPage/ProductsPage';
import Course from './pages/CoursesPage/CoursePage';
import Test from './pages/TestPage/Test';
import CourseDescPage from './pages/CourseDescriptionPage/CourseDescPage';
import PaymentPage from './pages/PaymentPage/PaymentPage';
import CallPage from './pages/CallPage/CallPage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import CancellationPolicy from './pages/CancellationPolicy/CancellationPolicy';
import ContactUs from './pages/ContactUs/ContactUs';
import ConfirmationPage from './pages/ConfimationPage/ConfirmationPage';
import { baseURL } from './utility/api';
import { MainContext } from './context/MainContext';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';

import MyCoursesPage from './pages/MyCoursesPage/MyCoursesPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import MySessionPage from './pages/MySessionPage/MySessionPage';
import ResumeMakerPage from './pages/ResumeServicePage/ResumeService';

// Layout Component
const Layout = ({ children, showFooter, isOpen, handleSubMenu }) => (
  <div className='flex flex-col min-h-screen'>
    <Navbar isOpen={isOpen} handleSubMenu={handleSubMenu} />
    <main className="flex-grow">
      {children}
    </main>
    {showFooter && <Footer />}
  </div>
);

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const { user, setUser ,isLoggedIn,setIsLoggedIn,setIsLoading} = useContext(MainContext);



  const getUser = async () => {
    try {
      setIsLoading(true);
      const url = `${baseURL}/login/success`;
      const response = await axios.get(url, { withCredentials: true });
      setUser(response.data.user);

      response.data.user ? 
        setIsLoggedIn(true) : setIsLoggedIn(false);
    
      setIsLoading(false);
    } catch (err) {
      // Handle error
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleSubMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const closeSubMenu = () => {
    if (isOpen) setIsOpen(false);
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <HomePage />
            </Layout>
          }
        />
        <Route
          path="*"
          element={
            <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <ErrorPage />
            </Layout>
          }
        />
        <Route
          path="/products"
          element={
            <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <ProductsPage />
            </Layout>
          }
        />
        
        <Route
          path="/courses/:id"
          element={
            <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <CourseDescPage />
            </Layout>
          }
        />
        <Route
          path="/courses/:id/pay"
          element={
            <Layout showFooter={false} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <PaymentPage />
            </Layout>
          }
        />
         <Route
          path="/courses/:id/pay"
          element={
            <Layout showFooter={false} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <PaymentPage />
            </Layout>
          }
        />
        <Route
          path="/bookcall"
          element={
            <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <CallPage />
            </Layout>
          }
        />
        <Route
          path="/bookcall/pay/:id"
          element={
            <Layout showFooter={false} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <PaymentPage />
            </Layout>
          }
        />
        <Route
          path="/resume-maker"
          element={
            <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <ResumeMakerPage />
            </Layout>
          }
        />
        <Route
          path="/product/pay/:id"
          element={
            <Layout showFooter={false} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <PaymentPage />
            </Layout>
          }
        />
        <Route
          path="/test"
          element={
            <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <Test />
            </Layout>
          }
        />
        <Route
          path="/terms-of-service"
          element={
            <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <TermsAndConditions />
            </Layout>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <PrivacyPolicy />
            </Layout>
          }
        />
        <Route
          path="/cancellation-and-refund-policy"
          element={
            <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <CancellationPolicy />
            </Layout>
          }
        />
        <Route
          path="/contactus"
          element={
            <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <ContactUs />
            </Layout>
          }
        />
        <Route
          path="/confirmation-page/:orderId"
          element={
            <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
              <ConfirmationPage />
            </Layout>
          }
        />


        {/* PROTECTED ROUTES */}
        <Route
          path="/my-courses"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
                <MyCoursesPage />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/my-sessions"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
                <MySessionPage />
              </Layout>
            </PrivateRoute>
          }
        />

        <Route
          path="/view-courses/:id"
          element={
            <PrivateRoute isAuthenticated={isLoggedIn}>
              <Layout showFooter={true} isOpen={isOpen} handleSubMenu={handleSubMenu}>
                <Course />
              </Layout>
            </PrivateRoute>
          }
        />

      </Routes>
    </>
  );
}

export default App;
