import React, { useEffect, useState } from "react";
import {
  Rocket,
  FileText,
  MessageSquare,
  Clock,
  CheckCircle,
  ArrowRight,
  Star,
  Download,
  Plus,
  Minus,
  ArrowUpRight,
} from "lucide-react";
import DateTimePicker from "@/components/CallSection/DateTimePicker";

const Card = ({ children, className }) => (
  <div
    className={`bg-gray-900/50 backdrop-blur-xl border border-gray-800/60 rounded-xl hover:border-violet-500/30 transition-all duration-300 ${className}`}
  >
    {children}
  </div>
);

const ResumeService = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [hoveredFaq, setHoveredFaq] = useState(null);
  const [expandedFaq, setExpandedFaq] = useState(null);
  

  const faqs = [
    {
      q: "What if I don't have a resume?",
      a: "No worries! We'll create one from scratch based on your inputs during the call. Our expert writers will guide you through sharing your experience, skills, and achievements in a structured way. Think of it as having a conversation about your career journey – we'll handle turning it into a professional resume.",
      icon: "📝",
    },
    {
      q: "Can I request changes after you send the resume?",
      a: "Absolutely! One round of edits is included within 48 hours of delivery. We want you to be 100% satisfied with the final result. Our collaborative approach ensures your resume perfectly represents your professional story.",
      icon: "✏",
    },
    
    {
      q: "How long does the process take?",
      a: "We deliver your professionally crafted resume within 48 hours of our consultation call. Need it urgently? Let us know, and we'll try our best to accommodate rush requests!",
      icon: "⏱",
    },
  ];
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }
  , []);


  const stats = [
    { value: "500+", label: "Templates Delivered" },
    { value: "4.9", label: "Average Rating" },
    { value: "2 Days", label: "Delivery Time" },
  ];

  return (
    <div className="min-h-screen md:-mt-16 bg-gray-950 text-white">
       <div className="fixed inset-0 -z-10 bg-[linear-gradient(to_bottom,rgba(0,0,0,0)_0%,rgba(0,0,0,0.8)_100%)]">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSA2MCAwIEwgMCAwIDAgNjAiIGZpbGw9Im5vbmUiIHN0cm9rZT0icmdiYSg5OSwgMTAyLCAyNDEsIDAuMSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvcGF0dGVybj48L2RlZnM+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmlkKSIvPjwvc3ZnPg==')] opacity-20"></div>
      </div>
      <div
        className={`relative group transition-all duration-1000 delay-300 ${
          isVisible
            ? "opacity-100 translate-y-0"
            : "opacity-0 translate-y-10"
        }`}
      > 
      <div className="px-4 sm:px-6 lg:px-8  grid lg:grid-cols-2 gap-12 max-w-7xl mx-auto pt-24 lg:pt-40">
        <div className="space-y-8">
          <div className="inline-flex items-center gap-2 text-purple-400 text-sm">
            <div className="w-2 h-2 rounded-full bg-purple-400 animate-pulse"></div>
            Premium Offer - Limited Time!
          </div>

          <h1 className="text-4xl lg:text-6xl font-bold leading-tight">
          Resume 
            <br />
            <span className="bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent">
            Writing Service
            </span>
           
          </h1>

          <p className="text-gray-400 text-lg leading-relaxed">
          Leave the heavy lifting to us. We’ll shape your resume into something recruiters love to read—and can’t ignore. Simple, impactful, and totally you (with none of the fluff)!
          </p>

          {/* <div className="flex flex-col sm:flex-row gap-4">
            <button className="group bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 rounded-full text-base font-medium transition-all hover:shadow-lg hover:shadow-purple-500/25">
              <span className="flex items-center gap-2">
                Get Started Now
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
              </span>
            </button>
            <button className="group px-8 py-3 rounded-full text-base font-medium border border-gray-700 hover:border-purple-500 transition-all">
              <span className="flex items-center gap-2">
                View Samples
                <Download className="w-5 h-5 group-hover:translate-y-1 transition-transform duration-300" />
              </span>
            </button>
          </div> */}

<div className="grid grid-cols-3 gap-6">
                {stats.map((stat, index) => (
                  <Card key={index} className="p-4 text-center group">
                    <div className="text-2xl font-bold bg-gradient-to-r from-violet-400 to-indigo-400 bg-clip-text text-transparent group-hover:scale-110 transition-transform">
                      {stat.value}
                    </div>
                    <div className="text-sm text-gray-400">{stat.label}</div>
                  </Card>
                ))}
              </div>
        </div>

        <div
          className={`relative group transition-all duration-1000 delay-300 flex items-center ${
            isVisible
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-10"
          }`}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-violet-600/20 to-indigo-600/20 blur-3xl transition-all duration-500 group-hover:blur-4xl -z-10 "></div>

          <DateTimePicker title="When should we meet?" buttonText={"Book a Session"}  isReschedule={false} dynamicPricing ={false} id={102}/>
          </div>
                     
      </div>

      <div className="px-4 sm:px-6 lg:px-8 py-16 max-w-7xl mx-auto">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
          {[
            {
              icon: <FileText className="w-6 h-6" />,
              title: "Book Your Slot",
              description: "Start your journey to a better resume",
            },
            {
              icon: <MessageSquare className="w-6 h-6" />,
              title: "Strategy Call",
              description: "Share your career goals with us",
            },
            {
              icon: <Clock className="w-6 h-6" />,
              title: "Expert Writing",
              description: "We craft your perfect resume",
            },
            {
              icon: <CheckCircle className="w-6 h-6" />,
              title: "Success!",
              description: "Get your polished resume",
            },
          ].map((step, index) => (
            <div
              key={index}
              className={`relative overflow-hidden rounded-xl transition-all duration-500 cursor-pointer ${
                activeStep === index
                  ? "bg-gradient-to-br from-purple-600/20 to-blue-600/20"
                  : "bg-gray-800/50"
              }`}
              onMouseEnter={() => setActiveStep(index)}
            >
              <div className="p-6 relative z-10">
                <div
                  className={`w-12 h-12 rounded-full flex items-center justify-center mb-4 transition-all duration-300 ${
                    activeStep === index
                      ? "bg-purple-400 text-white"
                      : "bg-purple-400/20 text-purple-400"
                  }`}
                >
                  {step.icon}
                </div>
                <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-400 text-sm">{step.description}</p>
              </div>
              <div
                className={`absolute inset-0 bg-gradient-to-r from-purple-600/10 to-blue-600/10 transition-opacity duration-300 ${
                  activeStep === index ? "opacity-100" : "opacity-0"
                }`}
              ></div>
            </div>
          ))}
        </div>
      </div>

      <div className="px-4 sm:px-6 lg:px-8 py-16 bg-gray-900/50">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl lg:text-4xl font-bold mb-4">
              Frequently Asked Questions
            </h2>
            <p className="text-gray-400">
              Everything you need to know about our premium resume service
            </p>
          </div>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="group relative overflow-hidden rounded-xl transition-all duration-300"
                onMouseEnter={() => setHoveredFaq(index)}
                onMouseLeave={() => setHoveredFaq(null)}
              >
                <div
                  className={`absolute inset-0 bg-gradient-to-r from-purple-600/20 to-blue-600/20 transition-opacity duration-300 ${
                    hoveredFaq === index || expandedFaq === index
                      ? "opacity-100"
                      : "opacity-0"
                  }`}
                ></div>

                <div className="relative z-10">
                  <button
                    onClick={() =>
                      setExpandedFaq(expandedFaq === index ? null : index)
                    }
                    className="w-full text-left p-6 bg-gray-800/50 backdrop-blur-sm flex items-center justify-between"
                  >
                    <div className="flex items-center gap-4">
                      <span className="text-2xl">{faq.icon}</span>
                      <h3 className="text-lg font-semibold group-hover:text-purple-400 transition-colors duration-300">
                        {faq.q}
                      </h3>
                    </div>
                    <div
                      className={`transform transition-transform duration-300 ${
                        expandedFaq === index ? "rotate-180" : ""
                      }`}
                    >
                      {expandedFaq === index ? (
                        <Minus className="w-5 h-5 text-purple-400" />
                      ) : (
                        <Plus className="w-5 h-5 text-purple-400" />
                      )}
                    </div>
                  </button>

                  <div
                    className={`overflow-hidden transition-all duration-300 ${
                      expandedFaq === index ? "max-h-96" : "max-h-0"
                    }`}
                  >
                    <p className="px-6 pb-6 text-gray-400 bg-gray-800/50 backdrop-blur-sm">
                      {faq.a}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-12 p-8 rounded-xl bg-gradient-to-r from-purple-600/20 to-blue-600/20 backdrop-blur-sm text-center">
            <h3 className="text-xl font-semibold mb-4">
              Still have questions?
            </h3>
            <p className="text-gray-400 mb-6">
              Our team is here to help you make the right choice for your
              career.
            </p>
            <a className="group bg-purple-600 hover:bg-purple-700 px-6 py-3 rounded-full text-sm font-medium transition-all inline-flex items-center gap-2" href="mailto:support@off-campus.in">
              Contact Support
              <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </div>
        </div>
      </div>

      {/* <div className="px-4 sm:px-6 lg:px-8 py-16 bg-gradient-to-r from-purple-900/20 to-blue-900/20">
        <div className="max-w-3xl mx-auto text-center">
          <span className="inline-block text-purple-400 text-sm font-medium mb-4 px-4 py-2 rounded-full bg-purple-400/10">
            Limited Time Offer
          </span>
          <h2 className="text-3xl lg:text-4xl font-bold mb-6">
            Ready to Transform Your Career?
          </h2>
          <p className="text-gray-400 mb-8 max-w-2xl mx-auto">
            Join 500+ professionals who have already elevated their job search
            with our premium resume service.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button className="group bg-gradient-to-r from-purple-600 to-blue-600 px-8 py-3 rounded-full text-base font-medium transition-all hover:shadow-lg hover:shadow-purple-500/25">
              <span className="flex items-center gap-2">
                Get Started Now
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
              </span>
            </button>
            <button className="group px-8 py-3 rounded-full text-base font-medium border border-gray-700 hover:border-purple-500 transition-all">
              <span className="flex items-center gap-2">
                View Sample Resume
                <Download className="w-5 h-5 group-hover:translate-y-1 transition-transform duration-300" />
              </span>
            </button>
          </div>
        </div>
      </div> */}

      </div>
    </div>
  );
};

export default ResumeService;