import ContactPage from "@/components/contact/page";
import React from "react";

const ContactUs = () => {
  return (
    <div className="text-white margin container">
      <h1 className="text-white text-[1.5rem] font-[900] text-center">
        Contact Us
      </h1>
      <p className="text-white md:mx-40 mt-10">
        Last updated on 22-11-2024 20:50:58
        <br />
        <br />
        You may contact us using the information below:
        <br />
        <br />
        Merchant Legal entity name: CHARCHIT KURRA
        <br />
        Registered Address: 32,L.I.G Flats, Gaytri Enclave,Opp Khanpur Bus
        Depot, Deoli, Delhi, PIN: 110062
        <br />
        Operational Address: 32,L.I.G Flats, Gaytri Enclave,Opp Khanpur Bus
        Depot, Deoli, Delhi, PIN: 110062
        <br />
        Telephone No: 9582076112
        <br />
        E-Mail ID: offcampus.careers@gmail.com
        <br />
      </p>
      {/* <ContactPage/> */}
    </div>
  );
};

export default ContactUs;
