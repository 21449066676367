export const NavbarMenu = [
 
    {
      "id": 1,
      "name": "Home",
      "url": "/"
    },
    {
      "id": 2,
      "name": "Courses",
      "url": "/courses/101"
    },
    {
      "id": 3,
      "name": "Resources",
      "url": "/products"
    },
    {
      "id": 4,
      "name": "Session",
      "url": "/bookcall"
    }
    
    
  
  ]


  export const NavbarMenuMobile = [
    {
      "id": 1,
      "name": "My Session",
      "url": "/my-sessions"
    },
    {
      "id": 2,
      "name": "My Courses",
      "url": "/my-courses"
    }
    
    
  
  ]