import React from 'react'
import { BarChart3, Calendar, BookOpen, MessageSquare } from "lucide-react"

const FeatureSection = () => {
  return (
    <div className="container py-20 ">
      <div className="flex justify-center w-full  mb-8 ">
      <div className="flex flex-col items-center space-y-12">
          <div 
            className="inline-block rounded-full bg-emerald-500/10 px-6 py-2 border border-emerald-500/50"
            style={{
              boxShadow: '0 0 10px #10b981, 0 0 20px #10b981, 0 0 30px #10b981',
              animation: 'pulse 2s infinite'
            }}
          >
            <h2 
              className="text-white font-semibold"
              // style={{
              //   textShadow: '0 0 5px #10b981, 0 0 10px #10b981, 0 0 15px #10b981'
              // }}
            >
              WHY CHOOSE US
            </h2>
          </div>
          </div>
        
      </div>
      {/* <h2 className="md:text-5xl text-4xl mb-8  text-center font-style-gradient">
        Dive into online courses on {'' }
        <br className='md:hidden'/>
        diverse subjects
      </h2> */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FeatureCard
          icon={<BarChart3 className="w-6 h-6 text-white" />}
          title="One-Stop Solution"
          description="Comprehensive support through courses, 1:1 sessions, and exclusive digital resources to help you secure off-campus opportunities."
          color="bg-orange-500"
        />
        <FeatureCard
          icon={<Calendar className="w-6 h-6 text-white" />}
          title="Stand Out"
          description="Learn strategies and techniques that set you apart from 90% of the competition."
          color="bg-blue-500"
        />
        <FeatureCard
          icon={<BookOpen className="w-6 h-6 text-white" />}
          title="Affordable Excellence"
          description="Access premium guidance at prices lower than a pizza, with unmatched value for money."
          color="bg-yellow-500"
        />
        <FeatureCard
          icon={<MessageSquare className="w-6 h-6 text-white" />}
          title="Post-Session Support"
          description="Get continuous guidance with answers to all your doubts, even after your sessions."
          color="bg-green-500"
        />
      </div>
    </div>
  )
}
function FeatureCard({ icon, title, description, color }) {
  return (
    <div className="bg-secondary-300 p-6 rounded-lg shadow-lg flex items-start space-x-4">
      <div className={`${color} p-3 rounded-lg`}>{icon}</div>
      <div>
        <h3 className="font-semibold text-lg mb-2 text-white">{title}</h3>
        <p className="text-white text-sm">{description}</p>
      </div>
    </div>
  )
}

export default FeatureSection