
import LoginComponent from "@/components/LoginComponent/LoginComponent";
import { MainContext } from "@/context/MainContext";
import { baseURL } from "@/utility/api";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"

export default function ConfirmationPage() {
  const {orderId} = useParams();
  const [order,setOrder] = useState(null);
  const {isLoggedIn} = useContext(MainContext);
  useEffect(() => {
    async function fetchData() {
     const response = await axios.post(`${baseURL}/payment/order-status`, { orderId : orderId },
      {
        headers: {
          'Content-Type': 'application/json', 
          
        }
      }
     );
    setOrder(response.data);
    }
    fetchData();
  }, []);
  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-gray-800 text-gray-100 rounded-lg shadow-xl">
        <div className="pt-6 px-6 pb-8 flex flex-col items-center space-y-6">
          <svg
            className="w-16 h-16 text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h1 className="text-3xl font-bold text-center">Please Check your inbox to Access your purchase</h1>
          <p className="text-center text-gray-400">
            Your order has been successfully processed. We appreciate your business!
          </p>
          <div className="w-full bg-gray-700 h-px" />
          <div className="w-full space-y-4">
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Order Number:</span>
              <span className="font-semibold">#{orderId}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Total Amount:</span>
              <span className="font-semibold">₹{order?.order_amount}</span>
            </div>
            {order?.item_type === 'course' && 
            <div className="flex justify-between items-center">
            <span className="text-gray-400">Email:</span>
            <span className="font-semibold">{order?.email}</span>
          </div>}
          </div>
          <div className="w-full bg-gray-700 h-px" />
          <div className="flex items-center space-x-2 text-green-500">
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
              />
            </svg>
            <span>Your order {order?.payment_status}</span>
          </div>
          {order?.item_type === 'course' && !isLoggedIn ? <>
          <LoginComponent>
            <div
             
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out text-center"
            >
              Login to Access Your Purchase
            </div>
          </LoginComponent>
          </> : <><Link
            to={order?.link}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out text-center"
          >
            Access Your Purchase
          </Link></>}
          
          <p className="text-sm text-gray-400 text-center">
            A confirmation email has been sent to your registered email address.
          </p>
        </div>
      </div>
    </div>
  )
}

